<template>
  <article id="wrap">

    <router-view v-bind.sync="form"></router-view>

  </article>
</template>

<script>
export default {
  name: 'ContactParent',
  components: {},
  data() {
    return {
      form: {
        input_name: '',
        input_email: '',
        input_type: '',
        input_content: '',
      }
    };
  },
  computed: {
    loginUser () {
      return this.$store.state.loginUser;
    }
  },
  created () {
    if (this.$store.getters.isLogin) {
      this.form.input_name = `${this.loginUser.last_name} ${this.loginUser.first_name}`;
      this.form.input_email = this.loginUser.email;
    }
  }
}
</script>
